import React, {
    forwardRef,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"

import { useOnScreen } from "../../helpers/useOnScreen"

import Style from "./Stepper.module.css"

class IdGen {
    private counter: number = 0

    getId() {
        this.counter++

        return this.counter
    }
}

interface StepperContextValue {
    active: number
    timeLeft: number

    registerItem: (sort?: number) => number
    unregisterItem: (id: number) => void
    setActive: (id: number) => void
}
const StepperContext = React.createContext<StepperContextValue | undefined>(undefined)

/* eslint-disable @typescript-eslint/no-unused-vars */
const useStepperContext = () => {
    const context = useContext(StepperContext)

    if (!context) {
        throw new Error("provider is missing.")
    }

    return context
}

export const Stepper = forwardRef<HTMLUListElement, {
    children: ReactNode
}>(
    (
        { children },
        elementRef
    ) => {
        return <ul ref={elementRef}>
            {children}
        </ul>
    }
)

interface StepperProps {
    duration?: number
}
export const StepperProvider: React.FC<StepperProps> = ({ duration = 10, children }) => {
    const [items, setItems] = useState<{
        id: number
        sort?: number
    }[]>([])
    const [active, setActive] = useState(1)
    const [timeLeft, setTimeLeft] = useState(duration)

    const elementRef = useRef<HTMLUListElement>(null)

    const visible = useOnScreen(elementRef)

    const idGenerator = useMemo(() => {
        return new IdGen()
    }, [])

    const registerItem = useCallback((sort?: number) => {
        const id = idGenerator.getId()

        setItems(items => [
            ...items,
            {
                id,
                sort
            }
        ].sort((a, b) => {
            return (a.sort || 0) - (b.sort || 0)
        }))

        return id
    }, [idGenerator])

    const unregisterItem = useCallback((id: number) => {
        setItems(items => {
            const index = items.findIndex(({ id: elemId }) => elemId === id)

            return [
                ...items.slice(0, index),
                ...items.slice(index + 1)
            ].sort((a, b) => {
                return (a.sort || a.id) - (b.sort || b.id)
            })
        })
    }, [])

    useEffect(() => {
        setTimeLeft(duration)
    }, [active, duration, setTimeLeft])

    useEffect(() => {
        if (items.length === 0 || !visible) {
            return
        }

        if (timeLeft <= 0) {
            const index = items.findIndex(({ id }) => id === active) + 1

            setActive(index >= items.length ? items[0].id : items[index].id)

            setTimeLeft(duration)
            return
        }

        const interval = setTimeout(() => {
            setTimeLeft(timeLeft => timeLeft - 0.5)
        }, 500)

        return () => clearTimeout(interval)
    }, [items, active, timeLeft, duration, setTimeLeft, visible])

    const val = useMemo(() => {
        return {
            active,
            timeLeft,

            registerItem,
            unregisterItem,
            setActive
        }
    }, [active, timeLeft, registerItem, unregisterItem, setActive])

    return <StepperContext.Provider value={val}>
        <Stepper>
            {children}
        </Stepper>
    </StepperContext.Provider>
}
/* eslint-enable @typescript-eslint/no-unused-vars */

interface StepperItemProps {
    heading: string
    sort?: number
    icon: ReactNode
}
export const StepperItem: React.FC<StepperItemProps> = ({ heading, icon, sort, children }) => {
    return <li className={Style.itemWrap}>
        <div
            className={`${Style.item} ${Style.item__inactive}`}
        >
            <div className="flex items-center">
                <div className={Style.icon}>
                    {icon}
                </div>
                <div>
                    <span className={Style.heading}>{heading}</span>
                </div>
            </div>
        </div>
    </li>
}
