import React, { ReactNode } from "react"
import { Stepper } from "./Stepper/Stepper"

interface HighlightSectionProps {
    stepperItems: ReactNode
}
const HighlightSection: React.FC<HighlightSectionProps> = ({ stepperItems, children }) => {
    return <section className="highlight-section section-x-padding tablet:py-12 desktop:pt-32">
        <div className="flex flex-wrap desktop:flex-no-wrap">
            <div className="w-full desktop:w-1/2 tablet:mb-12 desktop:pr-32 flex flex-col">
                {children}
            </div>
            <div className="w-full desktop:w-1/2 pb-12 desktop:pb-32">
                <Stepper>
                    {stepperItems}
                </Stepper>
            </div>
        </div>
    </section>
}

export default HighlightSection
