import React from "react"
import Layout from "../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import PageSplash from "../../components/PageSplash/PageSplash"
import { ValueProp, ValuePropHeader } from "../../components/ValueProp/ValueProp"
import IconRow from "../../components/IconRow/IconRow"
import IconRowItem from "../../components/IconRow/IconRowItem"

import { MediaAndText } from "../../components/ImageAndText/ImageAndText"
import PreHeading from "../../components/PreHeading"

import LoginIcon from "../../images/icons/log-in.inline.svg"
import PieIcon from "../../images/icons/pie.inline.svg"
import ChevRightIcon from "../../images/icons/chev-right.inline.svg"
import MaximizeIcon from "../../images/icons/maximize.inline.svg"
import CopyIcon from "../../images/icons/copy.inline.svg"
import ScissorIcon from "../../images/icons/scissor.inline.svg"
import LocationIcon from "../../images/icons/location.inline.svg"
import GlobeIcon from "../../images/icons/globe.inline.svg"
import Icon247 from "../../images/icons/24-7.inline.svg"
import BarChartIcon from "../../images/icons/bar-chart.inline.svg"
import LayersIcon from "../../images/icons/layers.inline.svg"
import TabletIcon from "../../images/icons/tablet.inline.svg"
import TrendingUpIcon from "../../images/icons/trending-up.inline.svg"
import UmbrellaIcon from "../../images/icons/umbrella.inline.svg"
import FilterIcon from "../../images/icons/filter.inline.svg"
import MessageIcon from "../../images/icons/message.inline.svg"
import SmileIcon from "../../images/icons/smile.inline.svg"
import UsersIcon from "../../images/icons/users.inline.svg"
import FeatherIcon from "../../images/icons/feather.inline.svg"
import SettingsIcon from "../../images/icons/settings.inline.svg"

import ScreenshotGameImage from "../../images/pages/products/culture/game-screenshot-de.svg"
import ScreenshotStatisticImage from "../../images/pages/products/culture/screenshot-statistic-de.svg"

import HighlightSection from "../../components/HighlightSection"
import { StepperItem } from "../../components/Stepper/Stepper"
import CustomerSlider from "../../components/CustomerSlider"
import DashboardScreenshotDe from "../../images/pages/products/culture/dashboard-screenshot-de"
import Seo from "../../components/Seo"
import { SupportDe } from "../../components/Support"
import { StartNowDe } from "../../components/StartNow"
import { PageType } from "../../index"
import ProductNav from "../../components/ProductNav/ProductNav"

const CultureDe: PageType = ({ location }) => {
    const {
        splash,
    } = useStaticQuery(graphql`
        query CultureDe {
            splash: file(relativePath: { eq: "pages/products/culture/splash.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 680) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return <Layout location={location}>
        <Seo
            title="Schnelle Onboardings und starke Unternehmensstruktur"
            description="Schnelles und smartes Onboarding für eine starke Unternehmenskultur, Vermittlung der Vision und effektivem Employee Branding. Wie wichtig ist Onboarding? Wie lange dauert Onboarding?"
            keywords="Onboarding, Unternehmenskultur, Vision, Corporate Identity, Employee Branding"
        />
        <ProductNav />
        <PageSplash
            preHeading="Einfaches und schnelles Onboarding"
            heading="Viele Standorte, eine Vision"
            cta="Mehr erfahren"

            image={splash}
        />
        <span aria-hidden id="content" />
        <ValueProp>
            <ValuePropHeader
                preHeading="Wer vor Was"
                heading="Alleine ist man schnell, gemeinsam kommt man weit"
            >
                <p>
                    Entscheidend sind nicht die Worte, sondern das, was die Menschen vorleben. Handlungen folgen dem Gedanken, der Idee und dem Wissen. Genau da setzen wir an. Endlich Mitarbeiter effektiv onboarden, informieren, auf die Vision ausrichten und durchstarten.
                </p>
            </ValuePropHeader>

            <IconRow perRow={5}>
                <IconRowItem icon={<LoginIcon />}>
                    Effektive Onboardings
                </IconRowItem>
                <IconRowItem icon={<ChevRightIcon />}>
                    Schneller Wissenstransfer
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Abteilungsspezifische Analysen
                </IconRowItem>
                <IconRowItem icon={<MaximizeIcon />}>
                    Einfach skalierbar
                </IconRowItem>
                <IconRowItem icon={<CopyIcon />}>
                    Vorlagen für Trainings
                </IconRowItem>
            </IconRow>
        </ValueProp>
        <section className="section-x-margin">
            <MediaAndText
                cutOff
                noImageLimit
                contentSide={{
                    desktop: "left",
                    mobile: "bottom",
                }}
                image={<DashboardScreenshotDe className="w-full pointer-events-none" />}
            >
                <PreHeading>Employee Branding</PreHeading>
                <h2>Wertebotschafter</h2>
                <p>
                    Gemeinsame Werte und eine gemeinsame Vision, trotz unterschiedlicher Persönlichkeiten und Stärken. Mit Quadio erhalten Sie ein digitales und skalierbares Instrument, um aus Ihren Mitarbeitern Wertebotschafter zu schaffen.
                </p>
                <ul className="list-blue-bullet mt-8">
                    <li>Wirksames Employee Branding</li>
                    <li>Vermittlung von Unternehmenskultur</li>
                    <li>Übertragung der Unternehmensvision</li>
                </ul>
            </MediaAndText>
        </section>
        <section className="section-x-margin">
            <MediaAndText
                contentSide={{
                    desktop: "right",
                    mobile: "bottom",
                }}
                cutOff
                noImageLimit
                image={<img alt="" src={ScreenshotGameImage} className="w-full pointer-events-none" />}
            >
                <PreHeading>Onboarding</PreHeading>
                <h2>Alle an Bord?</h2>
                <p>
                    Ein mühsamer Onboarding-Prozess verursacht enorme Kosten. Schaffen Sie effektive, mühelose und personalisierte Onboarding-Erlebnisse, die die Vermittlung von Unternehmenswerten und -kultur fördern.
                </p>
                <ul className="list-blue-bullet mt-8">
                    <li>Effektive Lernerfahrung</li>
                    <li>Compliances nachhaltig rüberbringen</li>
                    <li>Individuell zugeschnitten</li>
                    <li>Wirksame Templates für einen reibungslosen Einsatz</li>
                </ul>
            </MediaAndText>
        </section>
        <HighlightSection
            stepperItems={<>
                <StepperItem
                    heading="Individuell zugeschnitten"
                    icon={<ScissorIcon />}
                >
                    Ihre Werte, Ihre Vision, Ihre Unternehmenskultur und Ihr Design. Damit sich Ihre Mitarbeiter mit Ihrem Unternehmen identifizieren können.
                </StepperItem>
                <StepperItem
                    heading="Ortsunabhängig schulen"
                    icon={<LocationIcon />}
                />
                <StepperItem
                    heading="Zeitlich ungebunden"
                    icon={<Icon247 />}
                />
                <StepperItem
                    heading="Analysen und Messungen"
                    icon={<BarChartIcon />}
                />
                <StepperItem
                    heading="Einheitliche Qualität"
                    icon={<GlobeIcon />}
                />
            </>}
        >
            <div className="mb-8">
                <PreHeading>Distant Leading</PreHeading>
                <h2>Aus der Ferne führen</h2>
                <p>
                    Führung aus der Ferne birgt ihre eigenen Herausforderungen. Beziehungen zu pflegen, sich gemeinsam auf Ziele auszurichten und eine Unternehmenskultur zu prägen soll nun trotz Entfernung funktionieren. Klar, dass man hier Unterstützung braucht. Quadio flankiert Ihre wertvolle Führungsarbeit, indem die gemeinsame Vision und Identifikation mit dem Unternehmen in den Köpfen der Menschen sichergestellt wird.
                </p>
            </div>
        </HighlightSection>
        <section className="section-x-margin">
            <MediaAndText
                contentSide={{
                    desktop: "right",
                    mobile: "bottom",
                }}
                cutOff
                noImageLimit
                image={<img alt="" src={ScreenshotStatisticImage} className="w-full pointer-events-none" />}
            >
                <PreHeading>Echtzeit Analysen</PreHeading>
                <h2>Mehr verstehen, besser handeln</h2>
                <p>
                    Sie wissen, was Ihr Unternehmen weiß. Quadio gibt Ihnen Insights in Echtzeit, um auf Basis von Analysen Ihre Handlungen und neue Möglichkeiten ableiten zu können.
                </p>
            </MediaAndText>
        </section>
        <SupportDe>
            <li>Projektbegleitung und Strategieberatung</li>
            <li>Trainingserstellung für Ihr Unternehmen</li>
            <li>Requirements Engineering</li>
            <li>Individuelle Entwicklungen</li>
            <li>Skalierbare Employee Branding Aktionen</li>
        </SupportDe>
        <section className="section-x-margin section-x-padding">
            <PreHeading>Overview</PreHeading>
            <h2>Features</h2>

            <IconRow className="mt-16 mobile:mt-12" fluid>
                <IconRowItem icon={<LayersIcon />}>
                    Quiz-Trainings
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Statistiken
                </IconRowItem>
                <IconRowItem icon={<TabletIcon />}>
                    Von überall und jederzeit
                </IconRowItem>
                <IconRowItem icon={<TrendingUpIcon />}>
                    Live Daten und Analysen
                </IconRowItem>
                <IconRowItem icon={<UmbrellaIcon />}>
                    Employer Branding Ereignisse
                </IconRowItem>
                <IconRowItem icon={<FilterIcon />}>
                    Aktuelle Informationen
                </IconRowItem>
                <IconRowItem icon={<MessageIcon />}>
                    Instant Feedback
                </IconRowItem>
                <IconRowItem icon={<SmileIcon />}>
                    Gamification und Motivation
                </IconRowItem>
                <IconRowItem icon={<UsersIcon />}>
                    Benutzerverwaltung
                </IconRowItem>
                <IconRowItem icon={<FeatherIcon />}>
                    Content Management System
                </IconRowItem>
                <IconRowItem icon={<SettingsIcon />}>
                    Trainingsverwaltung
                </IconRowItem>
            </IconRow>
        </section>
        <section className="section-x-margin">
            <CustomerSlider />
        </section>
        <StartNowDe />
    </Layout>
}

export default CultureDe
